import React, { useEffect, useRef } from "react";
import Vector0 from "../assets/confetti/Vector-0.png";
import Vector1 from "../assets/confetti/Vector-1.png";
import Vector2 from "../assets/confetti/Vector-2.png";
import Vector3 from "../assets/confetti/Vector-3.png";
import Vector4 from "../assets/confetti/Vector-4.png";
import Vector5 from "../assets/confetti/Vector-5.png";
import Vector6 from "../assets/confetti/Vector-6.png";
import Vector7 from "../assets/confetti/Vector-7.png";
import Vector8 from "../assets/confetti/Vector-8.png";
import Vector9 from "../assets/confetti/Vector-9.png";
import Vector10 from "../assets/confetti/Vector-10.png";
import Vector11 from "../assets/confetti/Vector-11.png";
import Vector12 from "../assets/confetti/Vector-12.png";
import Vector13 from "../assets/confetti/Vector-13.png";
import Vector14 from "../assets/confetti/Vector-14.png";
import Vector15 from "../assets/confetti/Vector-15.png";
import Vector16 from "../assets/confetti/Vector-16.png";
import Vector17 from "../assets/confetti/Vector-17.png";
import Vector18 from "../assets/confetti/Vector-18.png";
import Vector19 from "../assets/confetti/Vector-19.png";
import Vector20 from "../assets/confetti/Vector-20.png";
import Vector21 from "../assets/confetti/Vector-21.png";
import Vector22 from "../assets/confetti/Vector-22.png";
import Vector23 from "../assets/confetti/Vector-23.png";
import Vector24 from "../assets/confetti/Vector-24.png";
import Vector25 from "../assets/confetti/Vector-25.png";
import Vector26 from "../assets/confetti/Vector-26.png";
import Vector27 from "../assets/confetti/Vector-27.png";
import Vector28 from "../assets/confetti/Vector-28.png";
import Vector29 from "../assets/confetti/Vector-29.png";
import Vector30 from "../assets/confetti/Vector-30.png";
import Vector31 from "../assets/confetti/Vector-31.png";
import Vector32 from "../assets/confetti/Vector-32.png";

const imgs = [
  Vector0,
  Vector1,
  Vector2,
  Vector3,
  Vector4,
  Vector5,
  Vector6,
  Vector7,
  Vector8,
  Vector9,
  Vector10,
  Vector11,
  Vector12,
  Vector13,
  Vector14,
  Vector15,
  Vector16,
  Vector17,
  Vector18,
  Vector19,
  Vector20,
  Vector21,
  Vector22,
  Vector23,
  Vector24,
  Vector25,
  Vector26,
  Vector27,
  Vector28,
  Vector29,
  Vector30,
  Vector31,
  Vector32,
];

const Confetti = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas: any = canvasRef?.current || ({} as HTMLCanvasElement);

    if (!canvas) return;
    const ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    const width = window.innerWidth;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    const wrapper = document.querySelector(".confetti");

    const confetti: { [key: string]: any } = [];
    const confettiCount =
      width >= 1024 ? 400 : width >= 768 ? 260 : width >= 480 ? 180 : 80;

    const gravity = 0.5;
    const terminalVelocity = 5;
    const drag = 0.075;

    const resizeCanvas = () => {
      const style = wrapper && getComputedStyle(wrapper);
      canvas.width = (style && parseInt(style["width"])) || 0;
      canvas.height = (style && parseInt(style["height"])) || 0;
    };

    const randomRange = (min: number, max: number) =>
      Math.random() * (max - min) + min;

    const initConfetti = () => {
      resizeCanvas();
      for (let i = 0; i < confettiCount; i++) {
        confetti.push({
          imageIndex: Math.floor(randomRange(0, imgs.length)),
          position: {
            x: randomRange(0, canvas.width),
            y: canvas.height - 1,
          },
          scale: {
            x: 1,
            y: 1,
          },
          velocity: {
            x: randomRange(-25, 25),
            y: randomRange(0, -50),
          },
        });
      }
    };

    const render = () => {
      ctx && ctx.clearRect(0, 0, canvas.width, canvas.height);

      confetti.forEach((confetto: { [key: string]: any }, index: number) => {
        ctx && ctx.translate(confetto.position.x, confetto.position.y);
        ctx && ctx.rotate(confetto.rotation);

        confetto.velocity.x -= confetto.velocity.x * drag;
        confetto.velocity.y = Math.min(
          confetto.velocity.y + gravity,
          terminalVelocity
        );
        confetto.velocity.x +=
          Math.random() > 0.5 ? Math.random() : -Math.random();

        confetto.position.x += confetto.velocity.x;
        confetto.position.y += confetto.velocity.y;

        if (confetto.position.y >= canvas.height) confetti.splice(index, 1);

        if (confetto.position.x > canvas.width) confetto.position.x = 0;
        if (confetto.position.x < 0) confetto.position.x = canvas.width;

        confetto.scale.y = Math.cos(confetto.position.y * 0.1);

        const imagePool = document.querySelector(".imagePool");
        const image =
          ((imagePool &&
            imagePool.childNodes[
              confetto.imageIndex
            ].cloneNode()) as CanvasImageSource) || new Image();
        ctx && ctx.drawImage(image, 0, 0);
        ctx && ctx.setTransform(1, 0, 0, 1, 0, 0);
      });

      if (confetti.length <= 10) initConfetti();
      window.requestAnimationFrame(render);
    };

    initConfetti();
    render();

    window.addEventListener("resize", resizeCanvas);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  return (
    <div className="confetti">
      <canvas ref={canvasRef} />
      <div className="imagePool">
        {imgs.map((img, index) => (
          <img src={img} alt="" key={index} />
        ))}
      </div>
    </div>
  );
};

export default Confetti;
