import React, { useEffect, useState } from "react";
import { GraphQLClient, gql } from "graphql-request";
import Egg from "../assets/egg.svg";
import Hand from "../assets/recordBoxHand.svg";
import Arrow from "../assets/recordBoxArrow.svg";

const { REACT_APP_CMS_URL, REACT_APP_CMS_TOKEN } = process.env;

const client = new GraphQLClient(REACT_APP_CMS_URL, {
  headers: {
    Authorization: `Bearer ${REACT_APP_CMS_TOKEN}`,
  },
});

const recordBoxQuery = gql`
  query {
    recordBox(where: { id: "ckz6yt76802ho0b091s24xw7o" }) {
      title
      records {
        id
        title
        albumCover {
          mimeType
          url
        }
      }
    }
  }
`;

const renderRecord = (record: any) => {
  const { albumCover, id } = record;
  return (
    <div
      style={{
        height: "20%",
        width: "35%",
        marginLeft: "30px",
        marginRight: "30px",
        marginTop: "10px",
        marginBottom: "10px",
        display: "flex",
      }}
      key={`record-${id}`}
      onClick={((id) => {
        console.log(`clicked! ${record.id}`)
        window.location.href = `/spin?id=${record.id}`
      })}
    >
      <img style={{ height: "100%", width: "100%" }} src={albumCover.url} />
    </div>
  );
};

const RecordSelection: React.FunctionComponent = () => {
  const [records, setRecords] = useState([]);
  const makeQuery = () => {
    return client
      .request(recordBoxQuery)
      .then((data) => {
        setRecords(data?.recordBox?.records);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    makeQuery();
  }, []);

  return records.length > 0 ? (
    <div
      style={{
        maxWidth: "100vw",
        maxHeight: "100vh",
        minHeight: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: 32,
        fontWeight: "bold",
        flexDirection: "row",
        padding: "40px",
        backgroundColor: "#2C4A43"
      }}
    >
      <div
        style={{
          maxWidth: "30vw",
          flex: 1,
          alignSelf: "flex-start",
        }}
      >
        <div>
          <img style={{height: '64px'}} src={Egg} />
        </div>
        <h3 style={{}}>Habitual record box</h3>
        <div style={{
        }}>
          <img style={{marginLeft: '50%'}} src={Arrow} />
        </div>
        <div>
          <h6>Select a record to spin</h6>
        </div>
        <div style={{
          position: 'absolute',
          bottom: '-10px',
          left: "10%"
        }}>
          <img src={Hand} />
        </div>
      </div>
      <div
        style={{
          flex: 1,
          minHeight: "80vh",
          flexDirection: "row",
          flexWrap: "wrap",
          display: "flex",
        }}
      >
        {records.map((r: any) => {
          return renderRecord(r);
        })}
      </div>
    </div>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: 32,
        fontWeight: "bold",
      }}
    >
      <div style={{}}>Loading</div>
    </div>
  );
};

export default RecordSelection;
