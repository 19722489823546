import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./index.css";
import "@fontsource/dm-serif-display";

import Spinner from "./routes/Spinner";
import RecordSelection from "./routes/RecordSelection";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="spin" element={<Spinner />} />
          <Route path="*" element={<RecordSelection />} />
          <Route index element={<RecordSelection />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
