import React, { useCallback, useEffect, useState } from "react";
import { Wheel, WheelDataType } from "react-custom-roulette";
import { StyleType } from "react-custom-roulette/dist/components/Wheel/types";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { gql, GraphQLClient } from "graphql-request";

import Hand from "../assets/hand.svg";
import Arrow from "../assets/arrow.svg";
import PickRevert from "../assets/pick-revert.svg";
import PickAccept from "../assets/pick-accept.svg";
import Egg from "../assets/egg.svg";
import RecordPlayerHappy from "../assets/record-player-happy-house.svg";
import Needle from "../assets/needle.svg";
import { NameChip } from "../components/NameChip";
import Confetti from "../components/Confetti";

const COLORS: StyleType[] = [
  {
    backgroundColor: "#869490",
  },
  {
    backgroundColor: "#B6BFBD",
  },
  {
    backgroundColor: "#B2B2B2",
  },
];

const graphcms = new GraphQLClient(`${process.env.REACT_APP_CMS_URL}`, {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
  },
});

const GUEST_LIST_QUERY = gql`
  query getGuestListsForRecord($recordId: ID!) {
    record(where: { id: $recordId }) {
      guestLists {
        guests
        title
      }
    }
  }
`;

interface GuestLists {
  title: string;
  guests: string[];
}

function Spinner() {
  const [searchParams] = useSearchParams();
  const [mustStartSpinning, setMustStartSpinning] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [spinButtonPressed, setSpinButtonPressed] = useState(false);
  const [handAnimationClass, setHandAnimationClass] =
    useState("idle-animation");
  const [guestLists, setGuestLists] = useState<GuestLists[]>([]);
  const [selection, setSelection] = useState<null | WheelDataType>();
  const [data, setData] = useState<WheelDataType[]>([
    {
      option: "Pick a guestlist",
    },
  ]);

  const [audio] = useState(new Audio(require("../assets/ticker.wav")));

  const setList = (guestLists: any[]) => {
    const lists = guestLists.map((item) => {
      return { value: item.guests, label: item.title };
    });
    setData(
      lists[0]?.value.map((name: string, index: number) => {
        return {
          option: getShownName(name),
          style: COLORS[index % COLORS.length],
        };
      })
    );
  };

  useEffect(() => {
    const getGuestList = async () => {
      const recordId = searchParams.get("id") || "ckz6ytiqw02ic0b13eqx9575g";
      const response = await graphcms.request(GUEST_LIST_QUERY, { recordId });
      setGuestLists(response.record.guestLists);
      setList(response.record.guestLists);
    };

    getGuestList();
  }, []);

  const getShownName = useCallback((name: any) => {
    const [head, ...tail] = name.split("");

    return `${head.toUpperCase()}${tail.join("")}`;
  }, []);

  const setNewPrizeNumber = useCallback(() => {
    setPrizeNumber(Math.floor(Math.random() * data.length));
  }, [data.length]);

  // load
  useEffect(() => {
    const names = searchParams.get("names")?.split(",");

    if (names) {
      setData(
        names?.map((name, index) => {
          return {
            option: getShownName(name),
            style: COLORS[index % COLORS.length],
          };
        })
      );
    }
  }, [getShownName, searchParams]);

  useEffect(() => {
    setNewPrizeNumber();
  }, [data, setNewPrizeNumber]);

  const spin = useCallback(() => {
    setTimeout(() => {
      audio.play();
    }, 1000);
    setMustStartSpinning(true);
    setHandAnimationClass("swipe-animation");

    setTimeout(() => {
      setHandAnimationClass("idle-animation");
    }, 5000);
  }, [audio]);

  const onWheelStop = useCallback(() => {
    setMustStartSpinning(false);
    setSelection(data[prizeNumber]);
  }, [data, prizeNumber]);

  const revertPick = useCallback(() => {
    setSelection(null);
    setNewPrizeNumber();
  }, [setNewPrizeNumber]);

  const acceptPick = useCallback(() => {
    setSelection(null);

    const copy = [...data];
    copy.splice(prizeNumber, 1);
    setData(copy);
  }, [data, prizeNumber]);

  const onSpinDown = useCallback(() => {
    spin();
    setSpinButtonPressed(true);
  }, [spin]);

  const onSpinUp = useCallback(() => {
    setSpinButtonPressed(false);
  }, []);

  return (
    <>
      {selection && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2000,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "black",
              opacity: 0.5,
            }}
            onClick={() => {
              setSelection(null);
            }}
          ></div>
          <div
            style={{
              width: "50%",
              height: "50%",
              maxWidth: "550px",
              maxHeight: "400px",
              borderRadius: "4px",
              background: "#CFD5F2",
              zIndex: 1000,
              display: "flex",
              fontSize: "44px",
              flexDirection: "column",
            }}
          >
            <Confetti />
            <div
              style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {getShownName(selection?.option)}!!!
            </div>
            <div
              style={{
                display: "flex",
                padding: "16px 32px",
                justifyContent: "space-between",
              }}
            >
              <img onClick={revertPick} src={PickRevert} alt="revert choice" />
              <img onClick={acceptPick} src={PickAccept} alt="accept choice" />
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
        }}
      >
        <div style={{ flex: 1, height: "100%" }}>
          <div style={{ flexGrow: 1, padding: 32 }}>
            <img src={Egg} alt={"Chaos Egg"} />
            <div style={{ fontSize: 32, marginTop: 32 }}>
              Who’s invited to the dancefloor?
            </div>

            <Select
              options={guestLists.map((item) => {
                return { value: item.guests, label: item.title };
              })}
              onChange={(item) => {
                if (item?.value) {
                  setData(
                    item?.value.map((name, index) => {
                      return {
                        option: getShownName(name),
                        style: COLORS[index % COLORS.length],
                      };
                    })
                  );
                }
              }}
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  marginTop: 16,
                }),
                control: (provided, state) => ({
                  ...provided,
                  background: "#2C4A43",
                }),
                placeholder: (provided, state) => ({
                  ...provided,
                  color: "white",
                }),
                indicatorSeparator: (provided, state) => ({
                  ...provided,
                  borderColor: "white",
                }),
                dropdownIndicator: (provided, state) => ({
                  ...provided,
                  color: "white",
                }),
                input: (provided, state) => ({
                  ...provided,
                  color: "white",
                }),
                singleValue: (provided, state) => ({
                  ...provided,
                  color: "white",
                }),
              }}
            />

            <div
              style={{
                display: "flex",
                alignSelf: "flex-start",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  marginTop: 10,
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                {data.map((item, index) => {
                  return (
                    <div
                      style={{ marginRight: 8, marginTop: 8 }}
                      key={`name-${index}`}
                    >
                      <NameChip
                        colour={item.style}
                        name={item.option || ""}
                        onPress={() => {
                          const newArray = [...data];
                          newArray.splice(index, 1);
                          setData([...newArray]);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: 2, height: "100%" }}>
          <div
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ position: "relative" }}>
              <img src={RecordPlayerHappy} alt="record player" />
              <div
                style={{ position: "absolute", left: "150px", top: "110px" }}
              >
                <div>
                  <div className="remove-images">
                    <Wheel
                      data={data}
                      mustStartSpinning={mustStartSpinning}
                      prizeNumber={prizeNumber}
                      onStopSpinning={onWheelStop}
                      radiusLineColor={"transparent"}
                      outerBorderWidth={0}
                      spinDuration={0.2}
                    />
                  </div>
                </div>
                <img
                  src={Needle}
                  alt="needle"
                  style={{
                    position: "absolute",
                    top: "-150px",
                    left: "350px",
                    zIndex: "1000",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "72%",
                    right: "95%",
                    zIndex: "1000",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div style={{ position: "absolute", top: "45%", left: 0 }}>
                    <img src={Arrow} alt="arrow" />
                    <div
                      style={{
                        fontSize: "32px",
                        transform: "rotate(-23.37deg)",
                        position: "absolute",
                        top: "-30px",
                        left: "-100px",
                      }}
                    >
                      Give it a spin
                    </div>
                  </div>
                  <img
                    className={handAnimationClass}
                    onClick={spin}
                    src={Hand}
                    alt="hand"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Spinner;
