import React from "react";

interface NameChipProps {
  colour: any;
  name: string;
  onPress?: () => void;
}

export const NameChip = ({ colour, name, onPress }: NameChipProps) => {
  return (
    <div
      style={{
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: colour?.backgroundColor ?? "white",
        color: colour?.textColor ?? "#2C4A43",
        borderRadius: 40,
        flexDirection: "row",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        maxHeight: 31,
        fontFamily: "sans-serif",
        fontWeight: 700,
      }}
    >
      <div>{name}</div>
      <div onMouseDown={onPress} style={{ marginLeft: 4, cursor: "pointer" }}>
        x
      </div>
    </div>
  );
};
